.bouncing-text {
    position: relative;
    display: flex;
    font-size: 6rem;
    font-family: arial black;  
  }
 
  .b, .o, .u, .n, .c, .e, .f, .g, .h {
    color: #b8bbbd;
    text-shadow: 0 3px #666769, 0 5px #666769, 0 7px #666769;
  }

  @supports (-webkit-touch-callout: none) {
    @media only screen and (max-device-width: 767px) {
      .bouncing-text {
        font-family: Helvetica, Arial, sans-serif;
      }
      .b, .o, .u, .n, .c, .e, .f, .g, .h {
        color: #b8bbbd;
        text-shadow: 0 1px #666769, 0 2px #666769, 0 3px #666769;
      }
      
    }
  }

  
  @keyframes bounce {
    0%   { transform: scale(1,1) translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-55px);}
    50%  { transform: scale(1.05,.95) translateY(0); }
    58%  { transform: scale(1,1) translateY(-7px); }
    65%  { transform: scale(1,1) translateY(0);}
    100% { transform: scale(1,1) translateY(0);}
}

.b {
animation: bounce 1s ease  ;
}
.o {
animation: bounce 1s ease   .1s;
}

.u {
animation: bounce 1s ease   .2s;
margin-right: 13px;
}

.n {
animation: bounce 1s ease   .3s;
}

.c {
animation: bounce 1s ease   .4s;
}

.e {
animation: bounce 1s ease   .5s;
}
.f {
animation: bounce 1s ease   .6s;
}
.g {
animation: bounce 1s ease   .7s;
}
.h {
animation: bounce 1s ease   .8s;
}
.bouncing-text:before, .bouncing-text:after {
    content:"";
    position: absolute;
    width: 50px;
    height:5px;
    border-radius:50%;
    top:82px;
    z-index:-1;
  }
  
  .bouncing-text:before {
    left:0;
    animation: scale 1s linear  ;
  }
  
  @keyframes scale {
    0% {transform: scaleX(1);}
    25% {transform: scaleX(0.4);}
    50% {transform: scaleX(1);}
    75% {transform: scaleX(0.9);}
    100% {transform: scaleX(1);}
  }
  
  .bouncing-text:after {
    left: 57px;
    animation: scale 1s linear   .1s;
  }
  
 

  /* @media screen and (max-width: 900px) {
    .bouncing-text{
      font-size: 4rem;
      width: fit-content;
      font-family: 'Raleway', sans-serif;  
      position: relative;
      display: flex;
    
    }
    .b, .o, .u, .n, .c, .e, .f, .g, .h {
      color: #b8bbbd;
    }
  } */

  @media screen and (min-width: 2560px){
    .bouncing-text{
      font-size: 10rem;
    }
  }

  @media screen and (min-width: 14410px) and (max-width: 2559px){

  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {

  }

  @media screen and (max-width:1023px){
    .bouncing-text{
      font-size: 3rem;
    }
  }

