@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
/* 
html{
  background-color:#000;
  border-bottom:solid 5px blue;
  border-top:solid 5px blue;
  overflow-x:hidden;
  padding-bottom:142px;
} */



.pacman{
  margin:40px 10px;
  width:200px;  /* same width as child elements */
  height:200px; /* double the height of child elements */
  position: relative; /* Positions the div relative to its normal position */
  /* overflow: hidden;  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pacman-top{
  background-color:yellow;
  height:100px;
  width:200px;
  border-radius:100px 100px 0 0;
  animation: spin1 0.5s infinite linear;
}

.pacman-bottom{
  background-color:yellow;
  height:100px;
  width:200px;
  border-radius:0 0 100px 100px;
  animation: spin2 0.5s infinite linear;
}

  

/* Animation*/

@keyframes spin1 {
	0%  {transform: rotate(0deg);}
	50%{transform: rotate(-35deg);}
  
}
@keyframes spin2 {
	0%  {transform: rotate(0deg);}
  50%{transform: rotate(35deg);}	
}

@-moz-keyframes spin1 {
	0%  {transform: rotate(0deg);}
	50%{transform: rotate(-35deg);}
  
}
@-moz-keyframes spin2 {
	0%  {transform: rotate(0deg);}
  50%{transform: rotate(35deg);}	
}

@-webkit-keyframes spin1 {
	0%  {transform: rotate(0deg);}
	50%{transform: rotate(-35deg);}
  
}
@-webkit-keyframes spin2 {
	0%  {transform: rotate(0deg);}
  50%{transform: rotate(35deg);}	
}

@media screen and (max-height: 1000px) and (max-width: 1023px) {
  .pacman {
    width: 100px;
    height: 100px
  }
  .pacman-top{
    background-color:yellow;
    height:50px;
    width:100px;
    border-radius:100px 100px 0 0;
    animation: spin1 0.5s infinite linear;
  }
  
  .pacman-bottom{
    background-color:yellow;
    height:50px;
    width:100px;
    border-radius:0 0 100px 100px;
    animation: spin2 0.5s infinite linear;
  }
}