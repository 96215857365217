body {
  align-items: center;
  background-color: #d8d9f2;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
}

.code-animation {
  width: 20rem;
  height: 20rem;
}

.code-animation .lines path {
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
}

.code-animation.animating .lines path {
  animation: coding 350ms ease-out forwards;
}

@keyframes coding {
  to {
    stroke-dashoffset: 0;
  }
}

.select-all {
  display: none;
}


@media screen and (max-height: 1000px) and (max-width: 1023px) {
  .code-animation{
    width: 10rem;
    height: 10rem;
  }
}